import "./InputPrice.scss";
import clsx from "clsx";
import React, {forwardRef, useEffect, useState} from "react";
import Select from "../Select/Select";
import {currencySymbol} from "../../../../utils/string";

const InputPrice = forwardRef(({
  placeholder = "",
  className = "",
  block = false,
  error = "",
  currencies = [],
  onCurrencyChange = null,
  label = null,
  currency = null,
  disableCurrencies = false,
  ...rest
}, ref) => {
  const [selectedCurrency, setSelectedCurrency] = useState(currencies?.[0].value);

  useEffect(() => {
    setSelectedCurrency(currency ?? currencies?.[0].value);
  }, [currency, currencies]);

  const handleCurrencySelect = (e) => {
    setSelectedCurrency(e.value);
    onCurrencyChange && onCurrencyChange(e);
  }

  return (
    <div className="app-input-price">
      <div className="app-input-price__currency">
        {selectedCurrency ? currencySymbol(selectedCurrency) : ""}
      </div>
      <input
        ref={ref}
        className={clsx(
          className,
          'app-input-price__input placeholder-white',
          block && 'app-input-price__input--block',
          error && 'app-input-price__input--error',
        )}
        type="number"
        step="any"
        placeholder={placeholder}
        {...rest}
      />
      {label &&
      <div className="app-input-price__label">
        {label}
      </div>
      }
      <div className="app-input-price__currencies">
        <div className="app-input-price__currencies__select">
          <div className="app-input-price__divider" />
          <Select
            onSelect={handleCurrencySelect}
            options={currencies}
            value={selectedCurrency}
            borderLess
            readOnly={rest.readOnly ?? false}
            disabled={rest.disabled || disableCurrencies}
          />
        </div>
      </div>
      {error && <p className="app-input-price__input__help">{error}</p>}
    </div>
  )
});

export default InputPrice;
