import { useSelector } from "react-redux";
import InvoiceInformation from "../../../components/Pages/Invoice/InvoiceInformation/InvoiceInformation";
import PaidInvoiceInformation from "../../../components/Pages/Invoice/PaidInvoiceInformation/PaidInvoiceInformation";
import { useEffect, useMemo } from "react";

function InvoiceHome() {
  const { invoice_page, hosted_data, livemode } = useSelector(
    (state) => state.invoice_app
  );

  useEffect(() => {
    if (hosted_data) {
      document.title = `${hosted_data?.merchant.business_name}${
        !livemode ? " - Testmode" : ""
      }`;
    }
  }, [hosted_data, livemode]);

  const showPaidInformation = useMemo(() => {
    return ["paid", "void", "uncollectible"].includes(invoice_page.status);
  }, [invoice_page]);

  return (
    <div className="app-container">
      {showPaidInformation ? (
        <PaidInvoiceInformation />
      ) : (
        <InvoiceInformation />
      )}
    </div>
  );
}

export default InvoiceHome;
