import "./SelectNetwork.scss";
import clsx from "clsx";
import ArrowDownIcon from "../../../assets/images/icons/dashboard/arrow_down.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Radio from "../Form/Radio/Radio";

function SelectNetwork({
  value = null,
  options = [],
  disabled = false,
  readOnly = false,
  borderLess = false,
  placeholder = "",
  className = "",
  style,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  useOutsideClick(selectRef, () => setOpen(false));

  useEffect(() => {
    setActiveIndex((index) => (value === null ? -1 : index));
  }, [value]);

  const handleOptionClick = (option, index) => {
    if (!option.is_active) return;

    setActiveIndex(index);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  const getActiveIndex = useMemo(() => {
    if (activeIndex === -1) {
      return null;
    }
    return options.length && options[activeIndex];
  }, [options, activeIndex]);

  return (
    <div
      ref={selectRef}
      className={clsx(className, "app-select")}
      style={style}
    >
      <div
        onClick={() => !readOnly && !disabled && setOpen(!open)}
        className={clsx(
          "app-select__input",
          open && "app-select__input--active",
          disabled && "app-select__input--disabled",
          borderLess && "app-select__input--borderless"
        )}
      >
        <div className="app-select__input__value">
          {getActiveIndex ? (
            <span>{getActiveIndex.network_name}</span>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open && (
        <div className="app-select-networks__options">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option, index)}
              className={clsx(
                "app-select-networks__options__item",
                !option.is_active &&
                  "app-select-networks__options__item--disabled",
                activeIndex === index &&
                  "app-select-networks__options__item--active"
              )}
            >
              <div className="app-select-networks__options__checkbox">
                <Radio
                  disabled={!option.is_active}
                  defaultChecked={activeIndex === index}
                  color="primary"
                  className="ml-1"
                  labelClassName="text-dark font-semibold cursor-pointer"
                  label={option.network_name}
                />
                {!option.is_active && (
                  <div className="app-select-networks__options__checkbox__currency">
                    Currently unavailable
                  </div>
                )}
                {/*<div className="app-select-networks__options__checkbox__currency">
                  {option.network_fee_currency ?? option.network_symbol}
                </div>*/}
              </div>
              <div className="app-select-networks__options__image">
                <img
                  src={require(`../../../assets/images/icons/brands/bitcoin.png`)}
                  alt="bitcoin"
                />
                {option.link && (
                  <a href={option.link} target="_blank" rel="noreferrer">
                    View Token
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectNetwork;
