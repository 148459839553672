import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/modal-close.svg";
import { setShowDetails } from "../../../store/app/invoiceAppSlice";
import clsx from "clsx";
import {
  currencyFormatter,
  currencyPrettier,
  currencySymbol,
} from "../../../utils/string";
import React, { useMemo } from "react";

function InvoiceDetails() {
  const dispatch = useDispatch();
  const { invoice_page, show_details } = useSelector(
    (state) => state.invoice_app
  );

  const handleCloseClick = () => {
    dispatch(setShowDetails(false));
  };

  const getInvoiceDiscount = useMemo(() => {
    const discountGroup = invoice_page.discount_amounts.find(
      (discount) => discount.source === "group"
    );

    if (discountGroup) {
      const discount = invoice_page.discounts.find(
        (discount) => discount.id === discountGroup.discount
      );

      return { ...discount, amount: discountGroup.amount };
    }

    return null;
  }, [invoice_page]);

  return (
    <div
      className={clsx(
        "app-invoice__sidebar",
        show_details && "app-invoice__sidebar--open"
      )}
    >
      <div className="app-invoice__sidebar__close">
        <CloseIcon onClick={handleCloseClick} />
      </div>
      <div className="app-invoice__sidebar__number">
        Invoice {invoice_page.invoice_number}
      </div>
      <div className="mt-8">
        <div className="space-y-3">
          {invoice_page.line_items.data.map((item, index) => (
            <div key={index} className="app-invoice__sidebar__product-row">
              <div className="app-invoice__table__row__item__name">
                <div>{item.product.name}</div>
                <div className="app-invoice__table__row__item__quantity">
                  <span>Qty {item.quantity}</span>
                </div>
              </div>
              <div className="app-invoice__sidebar__product-row__price">
                <div className="font-normal">
                  {currencyPrettier(item.currency, item.amount)}
                </div>
              </div>
            </div>
          ))}
        </div>
        <hr className="my-3" />
        <div className="app-invoice__sidebar__product-row">
          <div className="app-invoice__sidebar__key font-semibold">
            Subtotal
          </div>
          <div className="app-invoice__sidebar__value">
            {currencyPrettier(
              invoice_page.currency,
              invoice_page.subtotal
            )}
          </div>
        </div>
        {getInvoiceDiscount && (
          <div className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key">
              {getInvoiceDiscount && (
                <div className="whitespace-nowrap">
                  {getInvoiceDiscount.name}{" "}
                  {getInvoiceDiscount.amount_off &&
                    `(${currencyPrettier(
                      invoice_page.currency,
                      getInvoiceDiscount.amount_off
                    )} off)`}
                  {getInvoiceDiscount.percent_off &&
                    `(${getInvoiceDiscount.percent_off}% off)`}
                </div>
              )}
            </div>
            <div className="app-invoice__sidebar__value">
              <span className="font-normal">
                {currencyPrettier(
                  invoice_page.currency,
                  getInvoiceDiscount.amount
                )}
              </span>
            </div>
          </div>
        )}
        {invoice_page.shipping_rates.map((shipping_rate, index) => (
          <div key={index} className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key">
              {shipping_rate?.description
                ? shipping_rate.description
                : "Shipping"}
            </div>
            <div className="app-invoice__sidebar__value">
              <span className="font-normal">
                {currencyPrettier(invoice_page.currency, shipping_rate.amount)}
              </span>
            </div>
          </div>
        ))}
        {invoice_page.tax > 0 && (
          <div className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key">Total excluding tax</div>
            <div className="app-invoice__sidebar__value">
              {currencyPrettier(invoice_page.currency, invoice_page.total_excluding_tax)}
            </div>
          </div>
        )}
        {invoice_page.tax_amounts.map((tax_rate, index) => (
          <div key={index} className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key">
              {tax_rate.display_name} ({tax_rate.percent}%
              {tax_rate.inclusive ? " inc." : ""})
            </div>
            <div className="app-invoice__sidebar__value">
              <span className="font-normal">
                {currencyPrettier(invoice_page.currency, tax_rate.amount)}
              </span>
            </div>
          </div>
        ))}
        <div className="app-invoice__sidebar__product-row">
          <div className="app-invoice__sidebar__key">Total</div>
          <div className="app-invoice__sidebar__value">
            {currencyPrettier(invoice_page.currency, invoice_page.total)}
          </div>
        </div>
        <div className="app-invoice__sidebar__product-row">
          <div className="app-invoice__sidebar__key">Amount Due</div>
          <div className="app-invoice__sidebar__value">
            {currencyPrettier(invoice_page.currency, invoice_page.amount_due)}
          </div>
        </div>
        {invoice_page.amount_paid > 0 && (
          <div className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key">Paid</div>
            <div className="app-invoice__sidebar__value">
              {currencyPrettier(
                invoice_page.currency,
                invoice_page.amount_paid
              )}
            </div>
          </div>
        )}
        {invoice_page.amount_remaining > 0 && (
          <div className="app-invoice__sidebar__product-row">
            <div className="app-invoice__sidebar__key font-semibold">
              Remaining
            </div>
            <div className="app-invoice__sidebar__value">
              {currencyPrettier(
                invoice_page.currency,
                invoice_page.amount_remaining
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InvoiceDetails;
