import "./App.scss";
import AppRoutes from "./utils/routes";

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
