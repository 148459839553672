export const DASHBOARD_TABLE_LIMIT = 10;
export const ITEM_NEGATIVE_PRICE_REGEX = "^-?\\d+(\\.\\d{0,2})?$";
export const ITEM_PRICE_REGEX = "^\\d+(\\.\\d{0,2})?$";
export const DISCOUNT_TYPES = {
  standard: "Standard Discount",
  amount_gte: "Sale Amount",
  product: "Product Specific",
  product_quantity: "Product Quantity",
};
export const FIAT_CURRENCIES = [
  'usd', 'gbp', 'eur', 'aud', 'bhd', 'bmd', 'nzd', 'cad', 'chf', 'kwd', 'try', 'sgd', 'vef'
];
export const FIAT_CURRENCIES_SELECT = FIAT_CURRENCIES.map(currency => {
  return {label: currency?.toUpperCase(), value: currency}
});
