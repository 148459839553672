import { currencyPrettier } from "../../../../utils/string";
import { FaAngleRight } from "react-icons/fa";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowDetails } from "../../../../store/app/invoiceAppSlice";
import LabelTag from "../../../Common/LabelTag/LabelTag";
import InvoiceFooter from "../InvoiceFooter";
import InvoiceHeader from "../InvoiceHeader";
import { useFetchPayment } from "../../../../api/payment/payment";
import { dateFormatter } from "../../../../utils/date";
import clsx from "clsx";

function PaidInvoiceInformation() {
  const dispatch = useDispatch();

  const { hosted_data, invoice_page, show_details } = useSelector(
    (state) => state.invoice_app
  );

  const { data = null } = useFetchPayment({
    id: invoice_page?.last_payment,
    enabled: !!invoice_page?.last_payment,
  });

  const handleViewDetailsClick = () => {
    dispatch(setShowDetails(!show_details));
  };

  return (
    <div className="app-invoice__left">
      <div className="app-invoice__left__wrapper">
        <InvoiceHeader />
        <div className="app-invoice__card">
          <div className="flex items-start flex-wrap justify-between gap-4">
            <div className="app-invoice__total leading-none">
              <div className="app-invoice__total__amount">
                <div>
                  {currencyPrettier(invoice_page.currency, invoice_page.amount_due)}
                </div>
                <div className="app-invoice__total__currency">
                  {invoice_page.currency.toUpperCase()}
                </div>
                <LabelTag
                  color={clsx(
                    invoice_page.status === "paid" && "primary",
                    invoice_page.status === "part_paid" && "tertiary",
                    invoice_page.status === "void" && "warning",
                    invoice_page.status === "issued" && "secondary",
                    invoice_page.status === "over_due" && "danger",
                    invoice_page.status === "uncollectible" && "dark"
                  )}
                  className="ml-4"
                >
                  {invoice_page.status}
                </LabelTag>
              </div>
            </div>
            <div className="app-invoice__total leading-none">
              <small className="font-normal">Amount Paid</small>
              <div className="app-invoice__total__amount">
                <div>
                  {currencyPrettier(
                    invoice_page.currency,
                    invoice_page?.amount_paid
                  )}
                </div>
                <div className="app-invoice__total__currency">
                  {invoice_page.currency.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="app-invoice__sidebar__product-row">
              <div className="app-invoice__sidebar__key">Invoice Number</div>
              <div className="app-invoice__sidebar__value">
                {invoice_page.invoice_number}
              </div>
            </div>
            <div className="app-invoice__sidebar__product-row">
              <div className="app-invoice__sidebar__key">Payment Date</div>
              <div className="app-invoice__sidebar__value">
                {invoice_page.status_timeline?.paid_at
                  ? dateFormatter(invoice_page.status_timeline.paid_at, true)
                  : "-"}
              </div>
            </div>
            {data?.blockchain_transaction.tx_hash && (
              <div className="app-invoice__sidebar__product-row">
                <div className="app-invoice__sidebar__key">Last Payment</div>
                <div className="app-invoice__sidebar__value">
                  {data?.blockchain_transaction.explorer ? (
                    <a
                      href={data?.blockchain_transaction.explorer}
                      target="_blank"
                      rel="noreferrer"
                      className="text-tertiary"
                    >
                      {data?.blockchain_transaction.tx_hash.substring(0, 8)}...
                      {data?.blockchain_transaction.tx_hash.slice(-8)}
                    </a>
                  ) : (
                    <>
                      {data?.blockchain_transaction.tx_hash.substring(0, 8)}...
                      {data?.blockchain_transaction.tx_hash.slice(-8)}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 mb-6">
            <button
              onClick={handleViewDetailsClick}
              className="app-invoice__table__details"
            >
              <div>View Details</div>
              <FaAngleRight
                size="14"
                className="app-invoice__table__details__icon"
              />
            </button>
          </div>
          <div className="flex justify-between gap-4">
            <a href={invoice_page.invoice_pdf} download >
              <button
                className="app-invoice__custom-button app-invoice__custom-button--outline"
                style={{
                  borderColor: hosted_data.merchant.business_secondary_colour,
                  color: hosted_data.merchant.business_secondary_colour,
                }}
              >
                <span>Download Invoice</span>
              </button>
            </a>
            <button
              className="app-invoice__custom-button"
              style={{
                backgroundColor: hosted_data.merchant.business_secondary_colour,
              }}
            >
              Download receipt
            </button>
          </div>
        </div>
        <InvoiceFooter />
      </div>
    </div>
  );
}

export default PaidInvoiceInformation;
