import "./Select.scss";
import clsx from "clsx";
import ArrowDownIcon from "../../../../assets/images/icons/dashboard/arrow_down.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../../../hooks/useOutsideClick";

function Select({
  path = "",
  options = [],
  value = null,
  style,
  placeholder = "",
  className = "",
  disabled = false,
  readOnly = false,
  borderLess = false,
  ...rest
}) {
  const selectRef = useRef();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  useOutsideClick(selectRef, () => setOpen(false));

  const handleOptionClick = (option, index) => {
    setActiveIndex(index);
    setOpen(false);
    rest.onSelect && rest.onSelect(option);
  };

  useEffect(() => {
    setActiveIndex(options.findIndex((option) => option.value === value));
  }, [value, options]);

  const getActiveIndex = useMemo(() => {
    if (activeIndex === -1) {
      return null;
    }
    return options.length && options[activeIndex];
  }, [options, activeIndex]);

  const getIconUrl = (icon) => {
    try {
      return require(`../../../../${path}/${icon}.svg`);
    } catch (err) {
      return null;
    }
  };

  return (
    <div
      ref={selectRef}
      className={clsx(className, "app-select")}
      style={style}
    >
      <div
        onClick={() => !readOnly && !disabled && setOpen(!open)}
        className={clsx(
          "app-select__input",
          open && "app-select__input--active",
          disabled && "app-select__input--disabled",
          borderLess && "app-select__input--borderless"
        )}
      >
        <div className="app-select__input__value">
          {getActiveIndex ? (
            <>
              {getActiveIndex.icon &&
                getActiveIndex.icon !== "" &&
                (getIconUrl(getActiveIndex.icon) ? (
                  <img
                    className="app-select__input__icon"
                    src={getIconUrl(getActiveIndex.icon)}
                    alt=""
                  />
                ) : (
                  <span className="app-select__input__icon text-center">
                    {getActiveIndex.icon}
                  </span>
                ))}
              <span>{getActiveIndex.label}</span>
            </>
          ) : (
            <span>{placeholder}</span>
          )}
        </div>
        <img className="app-select__input__arrow" src={ArrowDownIcon} alt="" />
      </div>
      {open && (
        <div className="app-select__options">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option, index)}
              className={clsx(
                "app-select__options__item",
                index === activeIndex && "app-select__options__item--active"
              )}
            >
              {option.icon &&
                option.icon !== "" &&
                (getIconUrl(option.icon) ? (
                  <img
                    className="app-select__options__item__icon"
                    src={getIconUrl(option.icon)}
                    alt=""
                  />
                ) : (
                  <span className="app-select__options__item__icon text-center">
                    {option.icon}
                  </span>
                ))}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;
