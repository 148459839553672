import { currencyPrettier } from "../../../../utils/string";
import { ReactComponent as DateIcon } from "../../../../assets/images/icons/date.svg";
import { dateFormatter } from "../../../../utils/date";
import LabelTag from "../../../Common/LabelTag/LabelTag";
import clsx from "clsx";
import { ReactComponent as PDFIcon } from "../../../../assets/images/icons/pdf-invoice.svg";
import { FaAngleRight } from "react-icons/fa";
import React from "react";
import { setShowDetails } from "../../../../store/app/invoiceAppSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/images/icons/modal-close.svg";

function InvoiceDetailsInformation() {
  const dispatch = useDispatch();
  const { hosted_data, invoice_page, show_details } = useSelector(
    (state) => state.invoice_app
  );

  const handleViewDetailsClick = () => {
    dispatch(setShowDetails(!show_details));
  };

  return (
    <div className="app-invoice__card">
      <div className="flex justify-between gap-4">
        <div className="app-invoice__total leading-none">
          <div className="app-invoice__total__amount">
            <div>
              {currencyPrettier(
                invoice_page.currency,
                invoice_page.amount_remaining
              )}
            </div>
            <div className="app-invoice__total__currency">
              {invoice_page.currency.toUpperCase()}
            </div>
          </div>
        </div>
        {!invoice_page.paid && invoice_page.amount_paid > 0 && (
          <div>
            <div className="app-invoice__value mb-2">
              <span>Total due: </span>
              <span className="font-semibold">
                {currencyPrettier(invoice_page.currency, invoice_page.amount_due)}
              </span>
            </div>
            <div className="app-invoice__value">
              <span>Amount paid: </span>
              <span className="font-semibold">
                {currencyPrettier(
                  invoice_page.currency,
                  invoice_page.amount_paid
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      {invoice_page.due_date && (
        <div className="flex items-end gap-2">
          <div className="app-invoice__due">
            <DateIcon />
            <div>Due</div>
            <div className="app-invoice__due__date">
              {/* {dateFormatter(1653371933)} */}
              {dateFormatter(invoice_page.due_date)}
            </div>
          </div>
          <LabelTag
            color={clsx(
              invoice_page.status === "paid" && "primary",
              invoice_page.status === "part_paid" && "tertiary",
              invoice_page.status === "void" && "warning",
              invoice_page.status === "issued" && "secondary",
              invoice_page.status === "over_due" && "danger",
              invoice_page.status === "uncollectible" && "dark"
            )}
          >
            {invoice_page.status === 'part_paid' ? 'part paid' : invoice_page.status}
          </LabelTag>
        </div>
      )}
      <div className="flex flex-wrap justify-between items-end gap-4 mt-10">
        <div>
          <div className="mb-3">
            <div className="app-invoice__label">To</div>
            <div className="app-invoice__value">
              {invoice_page.customer.email ? invoice_page.customer.email : invoice_page.customer.name}
            </div>
          </div>
          <div>
            <div className="app-invoice__label">From</div>
            <div className="app-invoice__value">
              {hosted_data.merchant.support_email}
            </div>
          </div>
        </div>
        <div>
          <a href={invoice_page.invoice_pdf} download >
            <button className="app-invoice__download-button">
              <span>Download Invoice</span>
              <PDFIcon />
            </button>
          </a>
        </div>
      </div>
      <div className="mt-6">
        <button
          onClick={handleViewDetailsClick}
          className="app-invoice__table__details flex items-center"
        >
          <div>View Details</div>
          {show_details ? (
            <CloseIcon className="app-invoice__table__details__icon" />
          ) : (
            <FaAngleRight
              size="14"
              className="app-invoice__table__details__icon"
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default InvoiceDetailsInformation;
