import React, {Children, useEffect, useRef} from "react";
import "./Modal.scss";
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/modal-close.svg";
import clsx from "clsx";
import useOutsideClick from "../../../hooks/useOutsideClick";

const Slot = () => null;

function Modal({
  show = false,
  size = null,
  overflowVisible = false,
  footerBorder = false,
  children,
  ...rest
}) {
  const modalRef = useRef();
  const childrenArray = Children.toArray(children);
  const headerSlot = childrenArray.find(child => child?.props?.name === "header");
  const bodySlot = childrenArray.find(child => child?.props?.name === "body");
  const footerSlot = childrenArray.find(child => child?.props?.name === "footer");
  useOutsideClick(modalRef, () => show && handleClose());

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    return () => document.body.style.overflow = 'unset';
  }, [show]);

  const handleClose = () => {
    rest.onClose && rest.onClose();
  }

  if (!show) {
    return null;
  }

  return (
    <div
      className="app-modal"
      aria-labelledby="modal-title"
      role="dialog" aria-modal="true"
    >
      <div className="app-modal__wrapper app-modal__wrapper--centered">
        {/*Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
        Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"*/}
        <div className="app-modal__overlay" aria-hidden="true" />

        {/*<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>*/}

        {/*Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
        <div
          ref={modalRef}
          className={clsx(
          "app-modal__container",
            size && `app-modal__container--${size}`,
            overflowVisible && "app-modal__container--overflow-visible"
          )}
        >
          <div className="app-modal__header">
            <div className="app-modal__header__container" id="modal-title">
              {headerSlot?.props?.children}
              <div onClick={handleClose} className="app-modal__header__close">
                <CloseIcon />
              </div>
            </div>
          </div>
          <div className="app-modal__body">
            {bodySlot?.props?.children}
          </div>
          <div
            className={clsx(
              "app-modal__footer",
              footerBorder && "app-modal__footer--border"
            )}
          >
            {footerSlot?.props?.children}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.Slot = Slot;

export default Modal;
