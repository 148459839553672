import { Outlet, useParams } from "react-router-dom";
import "./Invoice.scss";
import { useEffect, useState } from "react";
import {
  setApiKey,
  setHostedData,
  setInvoicePage,
  setLiveMode,
  setPayment,
} from "../../store/app/invoiceAppSlice";
import {
  useFetchInvoicePage,
  useHostedInvoiceDetails,
} from "../../api/invoice/invoice";
import { useDispatch, useSelector } from "react-redux";
import InvoiceDetails from "../../components/Pages/Invoice/InvoiceDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetchPayment } from "../../api/payment/payment";

function Invoice() {
  const dispatch = useDispatch();
  const params = useParams();
  const [fetchPayment, setFetchPayment] = useState(false);
  const { hosted_data, invoice_page } = useSelector(
    (state) => state.invoice_app
  );

  useFetchPayment({
    id: invoice_page?.last_payment,
    enabled: fetchPayment,
    onSuccess: (data) => {
      dispatch(setPayment(data));
    },
  });

  const { mutate, isLoading } = useHostedInvoiceDetails();
  useFetchInvoicePage({
    onSuccess: (data) => {
      dispatch(setInvoicePage(data));
      if (
        !["paid", "void", "uncollectible"].includes(data.status) &&
        data.last_payment
      ) {
        setFetchPayment(true);
      }
    },
    onError: () => {
      window.location.replace("https://walio.io");
    },
    enabled: !!hosted_data,
    id: hosted_data?.invoice_id,
  });

  useEffect(() => {
    mutate(
      {
        merchant: params?.merchant,
        secret: params?.secret,
      },
      {
        onSuccess: (data) => {
          dispatch(setHostedData(data.data));
          dispatch(setApiKey(data.data?.ephemeral_key));
          dispatch(setLiveMode(data.data?.live_mode));
        },
        onError: () => {
          window.location.replace("https://walio.io");
        },
      }
    );
  }, [mutate, params, dispatch]);

  if (isLoading || !hosted_data || !invoice_page) {
    return null;
  }

  return (
    <div
      className="min-h-screen app-invoice__bg relative"
      style={{ backgroundColor: hosted_data?.merchant.business_primary_colour }}
    >
      <Outlet />
      <ToastContainer position="top-center" />
      <InvoiceDetails />
    </div>
  );
}

export default Invoice;
