import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const headers = () => ({
  "Walio-Api-Key": appStore.getState().invoice_app.api_key,
});

const hostedInvoiceDetails = (data) =>
  api.invoice_live.post(`/hosted/${data?.merchant}/${data?.secret}`);
export const useHostedInvoiceDetails = () => {
  return useMutation("hostedInvoiceDetails", hostedInvoiceDetails);
};

const fetchInvoicePage = (id) =>
  appStore.getState().invoice_app.livemode
    ? api.invoice_live.get(`/invoices/${id}`, { headers: headers() })
    : api.sandbox_invoice_live.get(`/invoices/${id}`, { headers: headers() });

export const useFetchInvoicePage = ({ onSuccess, onError, enabled, id }) => {
  return useQuery(["fetchInvoicePage", id], () => fetchInvoicePage(id), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

const payInvoice = (id, data) =>
  appStore.getState().invoice_app.livemode
    ? api.invoice_live.post(`/invoices/${id}/pay`, data, { headers: headers() })
    : api.sandbox_invoice_live.post(`/invoices/${id}/pay`, data, {
        headers: headers(),
      });
export const usePayInvoice = (id) => {
  return useMutation("payInvoice", (data) => payInvoice(id, data));
};
