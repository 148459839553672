import api from "../../utils/axios";
import { useMutation, useQuery } from "react-query";
import appStore from "../../store";

const headers = () => ({
  "Walio-Api-Key": appStore.getState().invoice_app.api_key,
});

const paymentEstimation = (data) =>
  appStore.getState().invoice_app.livemode
    ? api.invoice_live.post(`/payments/estimate`, data, { headers: headers() })
    : api.sandbox_invoice_live.post(`/payments/estimate`, data, {
        headers: headers(),
      });
export const usePaymentEstimation = () => {
  return useMutation("paymentEstimation", paymentEstimation);
};

const fetchPayment = (id) =>
  appStore.getState().invoice_app.livemode
    ? api.invoice_live.get(`/payments/${id}`, { headers: headers() })
    : api.sandbox_invoice_live.get(`/payments/${id}`, { headers: headers() });

export const useFetchPayment = ({
  onSuccess,
  onError,
  enabled,
  id,
  refetchInterval = false,
}) => {
  return useQuery(["fetchPayment", id], () => fetchPayment(id), {
    onSuccess,
    onError,
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchInterval,
    refetchOnWindowFocus: false,
  });
};

const cancelPayment = (id, data) =>
  appStore.getState().invoice_app.livemode
    ? api.invoice_live.post(`/payments/${id}/cancel`, data, {
        headers: headers(),
      })
    : api.sandbox_invoice_live.post(`/payments/${id}/cancel`, data, {
        headers: headers(),
      });
export const useCancelPayment = (id) => {
  return useMutation("cancelPayment", (data) => cancelPayment(id, data));
};
