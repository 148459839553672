import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import authReducer from "./app/authSlice";
import appInvoiceReducer from "./app/invoiceAppSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    invoice_app: appInvoiceReducer,
  },
});
