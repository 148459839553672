import { Route, Routes } from "react-router-dom";
import InvoiceLayout from "../layouts/Invoice/Invoice";
import InvoiceHome from "../pages/Invoice/Home/Home";
import Error from "../pages/Error/Error";

function AppRoutes() {
  return (
    <Routes>
      <Route
        element={
          <InvoiceLayout />
        }
      >
        <Route path=":merchant">
          <Route path=":secret" element={<InvoiceHome />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
