import Stack from "../../../Common/Stack/Stack";
import { cryptocurrencyFormatter } from "../../../../utils/string";
import Button from "../../../Common/Form/Button/Button";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCodeStyling from "qr-code-styling";
import Logo from "../../../../assets/images/logo.png";
import moment from "moment";
import { useFetchPayment } from "../../../../api/payment/payment";
import { setPayment } from "../../../../store/app/invoiceAppSlice";
import InvoiceInformationFinishedModal from "./InvoiceInformationModals/InvoiceInformationFinishedModal";
import InvoiceInformationCancelModal from "./InvoiceInformationModals/InvoiceInformationCancelModal";
import Label from "../../../Common/Form/Label/Label";
import InputGroup from "../../../Common/Form/InputGroup/InputGroup";
import { ReactComponent as ClipboardIcon } from "../../../../assets/images/icons/clipboard.svg";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import ReactTooltip from "react-tooltip";
import InvoicePaymentProcessAlert from "./InvoicePaymentProcessAlert";
// eslint-disable-next-line
import momentDuration from "moment-duration-format";

function InvoicePaymentProcess() {
  const dispatch = useDispatch();
  const qrRef = useRef(null);
  const [showCancelModal, setShowCancelModal] = useState(null);
  const [showFinishedModal, setShowFinishedModal] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const { hosted_data, payment } = useSelector((state) => state.invoice_app);
  const [copy, copyStatus] = useCopyToClipboard(
    payment?.blockchain_transaction?.to_addr
  );
  const [copyAmount, copyAmountStatus] = useCopyToClipboard(
    cryptocurrencyFormatter(payment?.blockchain_transaction?.amount)
  );

  useFetchPayment({
    id: payment?.id,
    refetchInterval: 60000,
    onSuccess: (data) => {
      dispatch(setPayment(data));
    },
  });

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 180,
        height: 180,
        type: "svg",
        data: payment?.blockchain_transaction?.to_addr,
        // image: Logo,
        dotsOptions: {
          color: "#000",
          type: "rounded",
        },
        cornersSquareOptions: { color: "#47CE7D", type: "extra-rounded" },
        cornersDotOptions: { color: "#6d5dd9" },
        // imageOptions: {
        //   crossOrigin: "anonymous",
        //   margin: 2,
        // },
      }),
    [payment]
  );

  useEffect(() => {
    qrCode.append(qrRef.current);
  }, [qrCode]);

  useEffect(() => {
    let qrUpdates = {
      data: payment?.blockchain_transaction?.to_addr,
    };
    
    let backgroundOptions;
    let cornersSquareOptions = {
      color: hosted_data?.merchant?.business_secondary_colour ? hosted_data.merchant.business_secondary_colour : "#47CE7D",
    };
    let cornersDotOptions = {
      color: hosted_data?.merchant?.business_primary_colour ? hosted_data.merchant.business_primary_colour : "#6d5dd9",
    };
    let dotsOptions = {
      color: "#000",
      type: "rounded",
    }
    let image = Logo;
    const imageOptions = {
      crossOrigin: "anonymous",
      margin: 2,
    }
    
    if (hosted_data) {

      if (hosted_data.merchant.qr_code_styling.background !== 'default') {
        backgroundOptions = {
          color: hosted_data.merchant.qr_code_styling.background
        };
      }
  
      if (hosted_data.merchant.qr_code_styling.corner_dots !== 'default') {
        cornersDotOptions.type = hosted_data.merchant.qr_code_styling.corner_dots
      }
  
      if (hosted_data.merchant.qr_code_styling.corner_square !== 'none') {
        cornersSquareOptions.type = hosted_data.merchant.qr_code_styling.corner_square === 'default' ? 'extra-rounded' : hosted_data.merchant.qr_code_styling.corner_square;
      }
  
      if (hosted_data.merchant.qr_code_styling.dots) {
        if (hosted_data.merchant.qr_code_styling.dots.colour !== 'default') {
          dotsOptions.color = hosted_data.merchant.qr_code_styling.dots.colour;
        }
  
        if (hosted_data.merchant.qr_code_styling.dots.shape !== 'default') {
          dotsOptions.type = hosted_data.merchant.qr_code_styling.dots.shape === 'leaf' ? 'classy' : (
            hosted_data.merchant.qr_code_styling.dots.shape === 'leaf_rounded' ? 'classy-rounded' : (
              hosted_data.merchant.qr_code_styling.dots.shape === 'rounded' ? 'extra-rounded' : hosted_data.merchant.qr_code_styling.dots.shape
            )
          );
        }
      }
  
      if (hosted_data.merchant.qr_code_styling.show_image) {
        if (hosted_data.merchant.qr_code_styling.use_logo) {
          if (hosted_data.merchant.logo) {
            image = hosted_data.merchant.logo;
          }
        } else if (hosted_data.merchant.icon) {
          image = hosted_data.merchant.icon;
        }

      } else image = null;
    }

    if (backgroundOptions) qrUpdates.backgroundOptions = backgroundOptions;
    qrUpdates.cornersSquareOptions = cornersSquareOptions;
    qrUpdates.cornersDotOptions = cornersDotOptions;
    qrUpdates.dotsOptions = dotsOptions;
    if (image) {
      qrUpdates.image = image;
      qrUpdates.imageOptions = imageOptions;
    }

    qrCode.update(qrUpdates);
  }, [qrCode, payment, hosted_data]);

  const getExpiryDiff = useMemo(() => {
    if (!payment?.blockchain_transaction) return false;
    return moment
      .unix(payment?.blockchain_transaction?.expiry)
      .diff(moment(), "seconds");
  }, [payment]);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = moment
        .unix(payment?.blockchain_transaction?.expiry)
        .diff(moment(), "seconds");

      const estimation = moment
        .duration(diff, "seconds")
        .format("mm:ss", { trim: false })
        .split(":");

      setExpiryTime({
        minutes: estimation[0],
        seconds: estimation[1],
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [payment]);

  const getCryptoIconUrl = useMemo(() => {
    try {
      return require(`assets/images/icons/cryptos/${payment?.blockchain_transaction?.currency?.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  }, [payment]);

  const handleFinishedClick = () => {
    if (payment?.status !== "pending") {
      window.location.replace("https://walio.io");
      return;
    }

    setShowFinishedModal(true);
  };

  const handleCancelClick = () => {
    setShowCancelModal(true);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="app-invoice__card mt-8">
      <InvoicePaymentProcessAlert expiryTime={expiryTime} />
      <div className="flex flex-wrap gap-5">
        <div className="sm:flex-none flex-1 flex justify-center">
          <div ref={qrRef} />
        </div>
        <div
          className="app-invoice__form__amount flex-1"
          style={{ width: "385px", maxWidth: "100%" }}
        >
          <div className="flex flex-wrap items-center">
            <img
              src={getCryptoIconUrl}
              alt={payment?.blockchain_transaction?.currency}
            />
            <span className="app-invoice__form__amount__number">
              {cryptocurrencyFormatter(payment?.blockchain_transaction?.amount)}
            </span>
            <span className="app-invoice__form__amount__currency">
              {payment?.blockchain_transaction?.currency?.toUpperCase()}
            </span>
            <div
              onClick={copyAmount}
              className="flex items-center ml-3 cursor-pointer"
              data-tip={""}
              data-for="copy-clipboard"
            >
              <ClipboardIcon />
            </div>
          </div>
          <div className="my-3">
            <Label title="to the address below" />
            <InputGroup
              type="text"
              block
              readOnly
              append={
                <div
                  onClick={copy}
                  className="flex items-center mt-3 cursor-pointer"
                  data-tip={""}
                  data-for="copy-clipboard"
                >
                  <ClipboardIcon />
                </div>
              }
              defaultValue={payment?.blockchain_transaction?.to_addr}
            />
          </div>

          {["succeeded", "confirming"].includes(
            payment?.blockchain_transaction?.status
          ) && (
            <>
              <Label title="Payment hash" />
              <a
                href={payment?.blockchain_transaction?.explorer}
                target="_blank"
                rel="noreferrer"
                className="text-tertiary underline"
              >
                {payment?.blockchain_transaction.tx_hash.substring(0, 8)}...
                {payment?.blockchain_transaction.tx_hash.slice(-8)}
              </a>
            </>
          )}
        </div>
      </div>
      <Stack columns="2" gap="4">
        <Button
          onClick={handleFinishedClick}
          style={{
            backgroundColor: hosted_data.merchant.business_secondary_colour,
          }}
          className="mt-2"
          block
        >
          Finished
        </Button>
        {getExpiryDiff < 90 && (
          <Button
            onClick={() => window.location.reload(false)}
            style={{ border: "1px solid #80858B4C" }}
            color="light"
            className="mt-2"
            block
          >
            Retry
          </Button>
        )}
        {getExpiryDiff >= 90 && (
          <Button
            onClick={handleCancelClick}
            style={{ border: "1px solid #80858B4C" }}
            color="light"
            className="mt-2"
            block
          >
            Cancel
          </Button>
        )}
      </Stack>
      <div className="app-invoice__input__helper">
        <div className="mt-7 mb-4">
          <div className="font-semibold">Disclaimer*</div>
          <span>
            Please only send one payment to the address above, additional
            payments will be lost.
          </span>
        </div>
        <div>
          <div>
            Ensure you pay enough blockchain transaction fees to allow the
            transaction to be confirmed
          </div>
          <div>within the set time.</div>
        </div>
      </div>
      {showFinishedModal && (
        <InvoiceInformationFinishedModal
          show={showFinishedModal}
          onClose={() => setShowFinishedModal(false)}
        />
      )}
      {showCancelModal && (
        <InvoiceInformationCancelModal
          show={showCancelModal}
          onClose={() => setShowCancelModal(false)}
        />
      )}
      <ReactTooltip
        id="copy-clipboard"
        place="bottom"
        effect="solid"
        type="light"
        padding="10px"
        textColor="#1A1E23"
        backgroundColor="#D3D8E0"
        className="api-key__tooltip"
        getContent={() =>
          copyStatus === "copied" || copyAmountStatus === "copied"
            ? "Copied!"
            : "Copy to clipboard"
        }
      />
    </div>
  );
}

export default InvoicePaymentProcess;
