import clsx from "clsx";
import { ReactComponent as DateIcon } from "../../../../assets/images/icons/date.svg";
import {
  cryptocurrencyFormatter,
  currencyPrettier,
} from "../../../../utils/string";
import { useSelector } from "react-redux";
import { useMemo } from "react";

function InvoicePaymentProcessAlert({ expiryTime = null }) {
  const { payment } = useSelector((state) => state.invoice_app);

  const getCryptoIconUrl = useMemo(() => {
    try {
      return require(`assets/images/icons/cryptos/${payment?.blockchain_transaction?.currency?.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  }, [payment]);

  const getCryptoPaymentIconUrl = useMemo(() => {
    try {
      return require(`assets/images/icons/cryptos/${payment?.crypto_currency?.toLowerCase()}.svg`);
    } catch (err) {
      return null;
    }
  }, [payment]);

  return (
    <>
      {payment && expiryTime !== null && (
        <div
          className={clsx(
            "app-invoice__form__estimation app-invoice__form__estimation--block app-invoice__form__estimation--start mb-5",
            expiryTime.minutes >= "03" &&
              expiryTime.seconds >= "00" &&
              "app-invoice__form__estimation--blue",
            ["pending"].includes(payment?.blockchain_transaction?.status) &&
              "app-invoice__form__estimation--yellow",
            ["succeeded", "over_paid", "under_paid"].includes(
              payment?.status
            ) && "app-invoice__form__estimation--green"
          )}
        >
          <div
            className="flex flex-col items-start gap-2"
            style={{ width: "550px", maxWidth: "100%" }}
          >
            {payment?.status === "pending" && (
              <>
                {payment?.blockchain_transaction?.status === "confirming" ? (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Confirming Payment (
                      {payment?.blockchain_transaction?.confirmations ?? 0} /{" "}
                      {payment?.blockchain_transaction
                        ?.confirmations_required ?? 0}
                      )
                    </div>
                    <span>
                      <div className="flex gap-1">
                        A payment of{" "}
                        <img
                          width="16"
                          src={getCryptoIconUrl}
                          alt={payment?.blockchain_transaction?.currency}
                        />
                        <span>
                          {payment?.blockchain_transaction?.currency?.toUpperCase()}
                        </span>
                        {cryptocurrencyFormatter(
                          payment?.blockchain_transaction?.amount
                        )}{" "}
                        was recorded and is being confirmed
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    {expiryTime.minutes > "00" && expiryTime.seconds > "00" ? (
                      <>
                        <div className="flex items-start gap-2">
                          <div className="app-invoice__form__estimation__header">
                            Awaiting payment
                          </div>
                          <DateIcon className="mt-1.5" />
                          <div className="app-invoice__form__estimation__time">
                            <span>{expiryTime.minutes}</span>
                            <div className="app-invoice__form__estimation__time__unit">
                              min
                            </div>
                          </div>
                          <span>:</span>
                          <div className="app-invoice__form__estimation__time">
                            <span>{expiryTime.seconds}</span>
                            <div className="app-invoice__form__estimation__time__unit">
                              sec
                            </div>
                          </div>
                        </div>
                        <span>
                          Please make your payment with the details below. This
                          attempt will expire soon
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="app-invoice__form__estimation__header">
                          Estimate expired
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {payment?.status === "succeeded" && (
              <>
                <div className="app-invoice__form__estimation__header">
                  Payment Successful
                </div>
                <span>
                  Thank you for your payment. If you provided an email address,
                  you will receive a payment receipt shortly
                </span>
              </>
            )}
            {payment?.status === "under_paid" && (
              <>
                <div className="app-invoice__form__estimation__header">
                  Payment Underpaid
                </div>
                <span>
                  Your payment was successful but was under paid by{" "}
                  {currencyPrettier(payment.currency, payment.amount_underpaid)}{" "}
                  <span className="inline-flex gap-1">
                    ({" "}
                    <img
                      width="16"
                      src={getCryptoPaymentIconUrl}
                      alt={payment.crypto_currency}
                    />{" "}
                    <span>{payment.crypto_currency?.toUpperCase()}</span>{" "}
                    {cryptocurrencyFormatter(payment?.crypto_amount_underpaid)}{" "}
                    )
                  </span>
                  . To ensure your purchase is not delayed, please contact us
                  for more help
                </span>
              </>
            )}
            {payment?.status === "over_paid" && (
              <>
                <div className="app-invoice__form__estimation__header">
                  Payment Overpaid
                </div>
                <span>
                  Payment was successful, but overpaid{" "}
                  {currencyPrettier(payment.currency, payment.amount_overpaid)}{" "}
                  <span className="inline-flex gap-1">
                    ({" "}
                    <img
                      width="16"
                      src={getCryptoPaymentIconUrl}
                      alt={payment.crypto_currency}
                    />{" "}
                    <span>{payment.crypto_currency?.toUpperCase()}</span>{" "}
                    {cryptocurrencyFormatter(payment?.crypto_amount_overpaid)}){" "}
                  </span>
                  . Please contact us for more help
                </span>
              </>
            )}
            {payment?.status === "failed" && (
              <>
                {payment?.failure_code === "expired" && (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Payment Attempt Expired
                    </div>
                    <span>
                      You did not complete the payment within the allowed time.
                      If you think there was an error, please contact us. Do not
                      attempt to send a payment to the old details below.
                    </span>
                  </>
                )}
                {payment?.failure_code === "blockchain_fail" && (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Blockchain Payment Failed
                    </div>
                    <span>
                      The payment has failed on the blockchain. Please start the
                      payment process again. Do not attempt to send a payment to
                      the old details below.
                    </span>
                  </>
                )}
                {payment?.failure_code === "misc" && (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Payment Failed
                    </div>
                    <span>
                      Your payment attempt has failed. Please restart the
                      payment process or contact us for more help. Do not
                      attempt to send a payment to the old details below.
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InvoicePaymentProcessAlert;
