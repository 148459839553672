import Logo from "../../../assets/images/logo-white.svg";
import React from "react";

function InvoiceFooter() {
  return (
    <div className="app-invoice__footer">
      <span>Powered by</span>
      <a
        href={"https://walio.io"}
        target="_blank"
        rel="noreferrer"
      >
        <img className="app-invoice__footer__logo" src={Logo} alt="Walio" />
      </a>
      <div className="vertical-divider" />
      <a href="/" target="_blank" rel="noreferrer">
        Terms
      </a>
      <a href="/" target="_blank" rel="noreferrer">
        Privacy
      </a>
    </div>
  );
}

export default InvoiceFooter;
