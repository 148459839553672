import Modal from "../../../../Common/Modal/Modal";
import { useEffect } from "react";

function InvoiceInformationFinishedModal({ show, onClose = null }) {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("https://walio.io");
    }, 7000);
  }, []);

  return (
    <Modal show={show} onClose={null} size="sm">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">Finished</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <div className="app-invoice__modal__body text-center">
          You will receive your payment receipt by email once your payment has
          cleared.
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default InvoiceInformationFinishedModal;
