import React, { useEffect, useMemo } from "react";
import InvoiceFooter from "../InvoiceFooter";
import InvoiceHeader from "../InvoiceHeader";
import ReactTooltip from "react-tooltip";
import InvoicePaymentInformation from "./InvoicePaymentInformation";
import InvoiceDetailsInformation from "./InvoiceDetailsInformation";
import { useSelector } from "react-redux";
import InvoicePaymentProcess from "./InvoicePaymentProcess";
import moment from "moment";

function InvoiceInformation() {
  const { payment } = useSelector((state) => state.invoice_app);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getExpiryDiff = useMemo(() => {
    if (!payment?.blockchain_transaction) return false;
    return moment
      .unix(payment?.blockchain_transaction?.expiry)
      .diff(moment(), "seconds");
  }, [payment]);

  return (
    <div className="app-invoice__left">
      <div className="app-invoice__left__wrapper">
        <InvoiceHeader />
        <InvoiceDetailsInformation />
        {payment && payment?.status === "pending" && getExpiryDiff > 90 ? (
          <InvoicePaymentProcess />
        ) : (
          <InvoicePaymentInformation />
        )}
        <InvoiceFooter />
      </div>
      <ReactTooltip
        place="top"
        effect="solid"
        type="light"
        padding="10px"
        textColor="#1A1E23"
        backgroundColor="#D3D8E0"
        className="app-tooltip"
      />
    </div>
  );
}

export default InvoiceInformation;
