import "./Input.scss";
import clsx from "clsx";
import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      type = "text",
      placeholder = "",
      className = "",
      size = null,
      block = false,
      error = "",
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <input
          ref={ref}
          className={clsx(
            className,
            "app-input placeholder-white",
            block && "app-input--block",
            size && `app-input--${size}`,
            error && "app-input--error"
          )}
          type={type}
          placeholder={placeholder}
          {...rest}
        />
        {error && <p className="app-input__help">{error}</p>}
      </>
    );
  }
);

export default Input;
