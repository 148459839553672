import axios from "axios";

const invoiceInstance = axios.create({
  baseURL: "https://invoice.walio.io/v1",
  withCredentials: true,
});

const sandboxInvoiceInstance = axios.create({
  baseURL: "https://invoice.walio.io/test/v1",
  withCredentials: true,
});

const modes = {
  invoice_live: invoiceInstance,
  sandbox_invoice_live: sandboxInvoiceInstance,
};

export default modes;
