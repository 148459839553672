import { createSlice } from "@reduxjs/toolkit";

export const invoiceAppSlice = createSlice({
  name: "invoice_app",
  initialState: {
    livemode: false,
    api_key: null,
    hosted_data: null,
    invoice_page: null,
    show_details: false,
    payment_estimation: null,
    payment: null,
  },
  reducers: {
    setLiveMode: (state, payload) => {
      state.livemode = payload.payload;
    },
    setApiKey: (state, payload) => {
      state.api_key = payload.payload;
    },
    setHostedData: (state, payload) => {
      state.hosted_data = payload.payload;
    },
    setInvoicePage: (state, payload) => {
      state.invoice_page = payload.payload;
    },
    setShowDetails: (state, payload) => {
      state.show_details = payload.payload;
    },
    setPaymentEstimation: (state, payload) => {
      state.payment_estimation = payload.payload;
    },
    setPayment: (state, payload) => {
      state.payment = payload.payload;
    },
  },
});

export const {
  setLiveMode,
  setApiKey,
  setHostedData,
  setInvoicePage,
  setShowDetails,
  setPaymentEstimation,
  setPayment,
} = invoiceAppSlice.actions;

export default invoiceAppSlice.reducer;
