import Modal from "../../../../Common/Modal/Modal";
import Button from "../../../../Common/Form/Button/Button";
import { useCancelPayment } from "../../../../../api/payment/payment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function InvoiceInformationCancelModal({ show, onClose }) {
  const { payment } = useSelector((state) => state.invoice_app);

  const { mutate, isLoading } = useCancelPayment(payment?.id);

  const handleYesClick = () => {
    mutate(null, {
      onSuccess: () => {
        window.location.replace("https://walio.io");
      },
      onError: (error) => {
        toast.error(error.response?.data.error.message);
      },
    });
  };

  return (
    <Modal show={show} onClose={onClose} size="sm">
      <Modal.Slot name="header">
        <div>
          <div className="app-invoice__modal__title">Cancel</div>
        </div>
      </Modal.Slot>
      <Modal.Slot name="body">
        <div className="app-invoice__modal__body text-center">
          Are you sure you want to cancel this payment?
        </div>
      </Modal.Slot>
      <Modal.Slot name="footer">
        <div className="flex gap-4">
          <Button block color="light" onClick={onClose}>
            No
          </Button>
          <Button disabled={isLoading} block onClick={handleYesClick}>
            Yes
          </Button>
        </div>
      </Modal.Slot>
    </Modal>
  );
}

export default InvoiceInformationCancelModal;
