import "./Label.scss";

function Label({
  title = "",
  icon = null,
  tooltip = "",
  tooltipHtml = false,
  error = "",
}) {

  return (
    <div className="app-input__label">
      <span className={error ? "app-input__label--error" : ""}>{title}</span>
      {icon &&
      <div className="app-input__label__icon">
        <img data-html={tooltipHtml} data-tip={tooltip} src={icon} alt={title}/>
      </div>
      }
    </div>
  )
}

export default Label;
