import React from "react";
import { useSelector } from "react-redux";

function InvoiceTestModeTag() {
  const { livemode } = useSelector((state) => state.invoice_app);

  return (
    <>
      {!livemode && (
        <div className="app-testmode__label">
          <span>Test mode</span>
        </div>
      )}
    </>
  );
}

export default InvoiceTestModeTag;
