import moment from "moment";

export function dateFormatter(date = "", time = false) {
  if (!date) {
    return "Invalid date";
  }

  const momentDate = moment.unix(date);

  return momentDate.format(
    `DD MMM${momentDate.year() !== moment().year() ? ", YYYY" : ""}${
      time ? " - HH:mm" : ""
    }`
  );
}
