function Error() {
  return (
    <div className="app-dashboard-container">
      <div className="app-dashboard__content">
        <div className="flex items-center justify-center h-4/5">
          <div className="text-center">
            <p className="h1 mb-4">404</p>
            <p className="h5 text-light">Page Not Available</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error;
