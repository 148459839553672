import React from "react";
import { useSelector } from "react-redux";
import InvoiceTestModeTag from "../../InvoicePage/InvoiceTestModeTag/InvoiceTestModeTag";

function InvoiceHeader() {
  const { hosted_data } = useSelector((state) => state.invoice_app);

  return (
    <div className="app-invoice__header">
      {hosted_data?.merchant.use_logo_instead_of_icon && hosted_data?.merchant.logo ? (
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="app-invoice__header__logo">
            <a
              href={hosted_data?.merchant.business_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={hosted_data?.merchant.logo}
                alt={hosted_data?.merchant.business_name}
              />
            </a>
          </div>
          <InvoiceTestModeTag />
        </div>
      ) : (
        <div className="flex items-center gap-3">
          {hosted_data?.merchant.icon && (
            <a
              href={hosted_data?.merchant.business_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="app-invoice__header__icon"
                src={hosted_data?.merchant.logo}
                alt={hosted_data?.merchant.business_name}
              />
            </a>
          )}
          <div className="app-invoice__header__name">
            {hosted_data?.merchant.business_name}
          </div>
          <InvoiceTestModeTag />
        </div>
      )}
    </div>
  );
}

export default InvoiceHeader;
