import Stack from "../../../Common/Stack/Stack";
import Label from "../../../Common/Form/Label/Label";
import InputPrice from "../../../Common/Form/InputPrice/InputPrice";
import { FIAT_CURRENCIES_SELECT } from "../../../../utils/constants";
import {
  cryptocurrencyFormatter,
  currencyFormatter,
  currencyConverter,
  currencySymbol,
  sortCrypto,
} from "../../../../utils/string";
import InfoIcon from "../../../../assets/images/icons/info.svg";
import { ReactComponent as ErrorInfoIcon } from "../../../../assets/images/icons/info-red.svg";
import { ReactComponent as CarbonZeroIcon } from "../../../../assets/images/icons/carbon-zero.svg";
import Select from "../../../Common/Form/Select/Select";
import Input from "../../../Common/Form/Input/Input";
import Button from "../../../Common/Form/Button/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SelectNetwork from "../../../Common/SelectNetwork/SelectNetwork";
import moment from "moment";
import { usePaymentEstimation } from "../../../../api/payment/payment";
import {
  setPayment,
  setPaymentEstimation,
} from "../../../../store/app/invoiceAppSlice";
import { usePayInvoice } from "../../../../api/invoice/invoice";
import { ReactComponent as DateIcon } from "../../../../assets/images/icons/date.svg";
// eslint-disable-next-line
import momentDuration from "moment-duration-format";

function InvoicePaymentInformation() {
  const dispatch = useDispatch();
  const [cryptoCurrency, setCryptoCurrency] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const { hosted_data, invoice_page, payment_estimation } = useSelector(
    (state) => state.invoice_app
  );

  const { mutate: mutatePayInvoice, isLoading: isLoadingPayInvoice } =
    usePayInvoice(hosted_data?.invoice_id);
  const { mutate: mutatePaymentEstimation, isLoading } = usePaymentEstimation();

  const fetchPaymentEstimation = useCallback(() => {
    mutatePaymentEstimation(
      {
        invoice: hosted_data?.invoice_id,
      },
      {
        onSuccess: (data) => {
          dispatch(setPaymentEstimation(data.data));
        },
        onError: () => {
          window.location.replace("https://walio.io");
        },
      }
    );
  }, [mutatePaymentEstimation, hosted_data, dispatch]);

  useEffect(() => {
    fetchPaymentEstimation();
  }, [invoice_page, fetchPaymentEstimation]);

  const cryptoCurrenciesOptions = useMemo(() => {
    if (!payment_estimation) return [];
    return sortCrypto(
      payment_estimation?.estimates.map((currency) => ({
        label: currency.crypto_currency,
        value: currency.crypto_currency?.toLowerCase(),
        icon: currency.crypto_currency?.toLowerCase(),
      }))
    );
  }, [payment_estimation]);

  useEffect(() => {
    setSelectedCurrency(invoice_page.currency);
  }, [invoice_page]);

  useEffect(() => {
    if (!cryptoCurrency && cryptoCurrenciesOptions.length) {
      setCryptoCurrency(cryptoCurrenciesOptions[0].value);
    }
  }, [cryptoCurrency, cryptoCurrenciesOptions]);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = moment
        .unix(payment_estimation?.expiry)
        .diff(moment(), "seconds");

      const estimation = moment
        .duration(diff, "seconds")
        .format("mm:ss", { trim: false })
        .split(":");

      setExpiryTime({
        minutes: estimation[0],
        seconds: estimation[1],
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [payment_estimation]);

  const { register, watch } = useForm({
    defaultValues: {
      amount: invoice_page.amount_remaining
        ? currencyFormatter(
            invoice_page.currency,
            invoice_page.amount_remaining
          )
        : "",
      receipt_email: invoice_page.customer?.email ?? "",
    },
  });

  const amount = watch("amount");
  const receipt_email = watch("receipt_email");

  const handleNewEstimates = () => {
    if (isLoading) return;
    fetchPaymentEstimation();
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.value);
  };

  const handleCryptoCurrencySelect = (currency) => {
    setCryptoCurrency(currency?.value);
    setSelectedNetwork(null);
  };

  const getCryptoIconUrl = useMemo(() => {
    try {
      return require(`assets/images/icons/cryptos/${cryptoCurrency}.svg`);
    } catch (err) {
      return null;
    }
  }, [cryptoCurrency]);

  const getEstimation = useMemo(() => {
    return payment_estimation?.estimates.find(
      (currency) =>
        currency.crypto_currency?.toLowerCase() ===
        cryptoCurrency?.toLowerCase()
    );
  }, [payment_estimation, cryptoCurrency]);

  const handleNetworkSelect = (e) => {
    setSelectedNetwork(e);
  };

  const handlePayInvoiceClick = () => {
    let formData = {
      method: "crypto",
      crypto_currency: cryptoCurrency?.toUpperCase(),
      network: selectedNetwork?.network,
      receipt_email,
    };
    // format this value appropriatly
    if (invoice_page.partial_payment) {
      formData.amount = currencyConverter(
        invoice_page.currency,
        Number(amount)
      );
    }

    if (payment_estimation && payment_estimation.id) {
      formData.estimate = payment_estimation.id;
    }
    // Remove any null values, otherwise API will throw error
    if (!formData.receipt_email || formData.receipt_email.length < 1) {
      delete formData.receipt_email;
    }

    mutatePayInvoice(formData, {
      onSuccess: (data) => {
        dispatch(setPayment(data.data));
      },
      onError: () => {
        window.location.replace("https://walio.io");
      },
    });
  };

  return (
    <div className="app-invoice__card mt-8">
      <Stack columns="1" gap="4">
        <div className="flex flex-wrap gap-4 justify-between">
          <div className="flex flex-col justify-between">
            <div className="app-invoice__input__group__item">
              <span>Payment Details</span>
            </div>
            <div className="app-invoice__form__amount__label sm:block hidden">
              <div className="flex justify-between">
                <span>Amount</span>
              </div>
            </div>
          </div>
          {payment_estimation && expiryTime !== null && (
            <>
              <div className="app-invoice__form__estimation">
                {expiryTime.minutes > 0 && expiryTime.seconds > 0 ? (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Estimate Expires
                    </div>
                    <div className="flex items-start gap-2">
                      <DateIcon className="mt-1.5" />
                      <div className="app-invoice__form__estimation__time">
                        <span>{expiryTime.minutes}</span>
                        <div className="app-invoice__form__estimation__time__unit">
                          min
                        </div>
                      </div>
                      <span>:</span>
                      <div className="app-invoice__form__estimation__time">
                        <span>{expiryTime.seconds}</span>
                        <div className="app-invoice__form__estimation__time__unit">
                          sec
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="app-invoice__form__estimation__header">
                      Estimate expired
                    </div>
                    <div>
                      <span
                        onClick={handleNewEstimates}
                        className="font-semibold underline cursor-pointer"
                      >
                        Get new estimates
                      </span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className="app-invoice__form__amount__label sm:hidden block">
          <div className="flex justify-between">
            <span>Amount</span>
          </div>
        </div>
        {/*TODO: default the input amount to what is owing, and do not allow the currency to be changed (defaults to invoice.currency)*/}
        {invoice_page.partial_payment && (
          <div className="flex">
            <div>
              <Label title="Amount" />
              <InputPrice
                {...register("amount")}
                block
                currencies={FIAT_CURRENCIES_SELECT}
                onCurrencyChange={handleCurrencyChange}
                placeholder="0.00"
                max={invoice_page.amount_remaining}
                currency={selectedCurrency}
              /> 
            </div> 
          </div>
        )}
        <div className="app-invoice__form__amount">
          {!getEstimation?.is_active && (
            <div className="text-danger flex items-center gap-2">
              <ErrorInfoIcon />
              <span>This currency is currently not available</span>
            </div>
          )}
          <div className="flex items-center">
            <img
              width="24"
              src={getCryptoIconUrl}
              alt={cryptoCurrency}
              className="mt-1"
            />
            <span className="app-invoice__form__amount__number">
              {getEstimation
                ? cryptocurrencyFormatter(getEstimation.crypto_amount)
                : "0.00000000"}
            </span>
            <span className="app-invoice__form__amount__currency">
              {cryptoCurrency?.toUpperCase()}
            </span>
          </div>
          <div className="app-invoice__form__amount__exchange">
            <span className="inline-flex items-center gap-1">
              <span>Exchange rate: </span>
              <span>1</span>
              <img width="16" src={getCryptoIconUrl} alt={cryptoCurrency} />
              <span>{cryptoCurrency?.toUpperCase()}</span>
            </span>
            <span>=</span>
            <span>
              {currencySymbol(payment_estimation?.currency)}{" "}
              {getEstimation?.exchange_rate}
            </span>
          </div>
          <div className="mt-2">
            {invoice_page.crypto_payment_padding > 0 && (
              <div className="flex items-center gap-2">
                <div className="app-invoice__form__amount__label">
                  Merchant Fee:{" "}
                  {cryptocurrencyFormatter(
                    getEstimation?.crypto_padding_amount
                  )}{" "}
                  {cryptoCurrency?.toUpperCase()}
                </div>
                <img
                  className="mt-0.5"
                  data-tip={`This Merchant adds and additional ${invoice_page.crypto_payment_padding}% fee to cover their blockchain related expenses. This allows more merchants to allow cryptocurrency payments with ease`}
                  src={InfoIcon}
                  alt="Info"
                />
              </div>
            )}
            {hosted_data.walio_zero_carbon && (
              <div className="flex items-center gap-2">
                <CarbonZeroIcon style={{ width: "20px" }} />
                <div className="app-invoice__form__amount__label">
                  This merchant will donate {hosted_data.walio_zero_carbon}% of
                  your payment to help fight climate change
                </div>
              </div>
            )}
          </div>
        </div>
        <Stack columns="12" gap="5" className="mt-2">
          <div className="sm:col-span-4 col-span-12">
            <Label title="Cryptocurrency" />
            <Select
              onSelect={handleCryptoCurrencySelect}
              path="assets/images/icons/cryptos"
              options={cryptoCurrenciesOptions}
              value={cryptoCurrency}
            />
          </div>
          <div className="sm:col-span-8 col-span-12">
            <Label title="Blockchain Network" />
            <SelectNetwork
              value={selectedNetwork}
              disabled={!getEstimation?.is_active}
              options={getEstimation?.networks ?? []}
              onSelect={handleNetworkSelect}
              placeholder="Select network"
            />
          </div>
        </Stack>
        <div>
          <Label
            title="Receipt Email"
            tooltip="If you want to receive an emailed receipt for this payment."
            icon={InfoIcon}
          />
          <Input {...register("receipt_email")} type="email" block />
        </div>
        {/*<div>
              <Label title="Name *" />
              <Input type="text" block />
            </div>
            <div>
              <button className="app-table__pagination__button">Next</button>
            </div>*/}
      </Stack>
      <Stack columns="1" gap="4">
        <div className="app-invoice__input__helper">
          <div className="mt-7 max-w-sm">
            * These prices are guaranteed within the Estimate time frame. Actual
            values may vary when confirming payment options, if the Estimate
            provided has expired.
          </div>
        </div>
        <Button
          disabled={
            isLoadingPayInvoice || !getEstimation?.is_active || !selectedNetwork
          }
          onClick={handlePayInvoiceClick}
          style={{
            backgroundColor: hosted_data.merchant.business_secondary_colour,
          }}
          className="app-invoice__form__button mt-2"
          block
        >
          Pay
        </Button>
      </Stack>
    </div>
  );
}

export default InvoicePaymentInformation;
